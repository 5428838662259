
import { Component, Vue, } from 'vue-property-decorator'
import VueEditor from '@/components/VueEditor/Index.vue'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"


@Component({
  components: {
    VueEditor,OverlayBackground
  },
  computed: {},
})
export default class ComplianceSumaryComponent extends Vue {
  private loading = false;
  private contentCompliance = ''
  private contentPending = ''
  private userId = this.$route.params.id
  private framework_selected = this.$store.state.PolimorficModule.PolimorficState.framework_selected

  private assignedContentCompliance (element: string) {
    this.contentCompliance = element
  }
  private assignedContentPending (element: string) {
    this.contentPending = element
  }

  private routerBack () {
    this.$router.go(-1)
  }

  private saveGapAnalysis () {
    this.loading = true

    this.$store.commit('GapModule/SET_COMPLETED_INFO', this.contentCompliance)
    this.$store.commit('GapModule/SET_PENDING_INFO', this.contentPending)

    this.$store
      .dispatch('GapModule/publicGap', this.userId)
      .then(() => {
        this.loading = false
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Éxito, Analisis gap publicado',
        })
        this.$router.go(-1)
      })
      .catch(() => {
        this.loading = false
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Error, Ha ocurrido un error inesperado',
          color_notification: 'danger',
        })
      })
  }
}
